import React, { useEffect } from 'react';

// Routing
import { Route, Routes, useLocation } from "react-router-dom";

// Pages
import { DashboardPage } from 'pages/dashboard';
import { AddTutoratPage } from 'pages/add-tutorat';
import { AddRecuperationPage } from 'pages/add-recuperation';
import { AddMaisonJeunesPage } from 'pages/add-maison-jeunes';
import { AddTutoratPrivePage } from 'pages/add-tutorat-prive';
import { AddCoachMeetingPage } from 'pages/add-coach-meeting';
import { DocumentsPage } from 'pages/documents';
import { ResourcesPage} from 'pages/resources';
import { ContactPage} from 'pages/contact';
import { FAQPage} from 'pages/faq';
import { AADPage } from 'pages/aad';  
import { HomePage } from 'pages/home';
import { Settings } from 'pages/settings/index';
import { ListUnsignedDocumentsPage, SignDocumentsPages } from 'pages/sign-documents';

// Auth
import { LoginPage } from 'pages/auth/login';
import { AdminLoginPage } from 'pages/auth/admin-login';
import { LogoutPage } from 'pages/auth/logout';
import { ForgotPasswordPage } from 'pages/auth/forgot-password';
import { ResetPasswordPage } from 'pages/auth/reset-password';

import { TutorInscriptionPage, nestedTutorInscriptionPages } from 'pages/tutor-inscription';
import { Error404 } from 'pages/errors/404';

// Components
import { AuthGuard, RequiredStepsGuard, ScopeGuard } from 'components/auth'

import ReactGa from 'react-ga4'
import { ConversationsListPage } from 'pages/conversations/list';
import { PlayTimeViewPage } from 'pages/conversations/playtime';
import { NoConversationSelected } from 'pages/conversations/empty';
import { UnsubscribePage } from 'pages/unsubscribe';

export function App(){
  const location = useLocation();
  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return (<Routes>
              <Route path="/login" element={<LoginPage/>}/>
              <Route path="/admin-login" element={<AdminLoginPage/>}/>
              <Route path="/logout" element={<LogoutPage/>}/>
              <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
              <Route path="/reset-password" element={<ResetPasswordPage/>}/>
              <Route path="/unsubscribe" element={<UnsubscribePage/>}/>
              <Route path="/activate-account" element={<ResetPasswordPage namespace='activate-account'/>}/>

              <Route path="/sign-required-documents" element={<AuthGuard><ListUnsignedDocumentsPage/></AuthGuard>}/>
              <Route path="/sign-required-documents/code-ethique" element={<AuthGuard><SignDocumentsPages.EthicFormPage/></AuthGuard>}/>
              <Route path="/sign-required-documents/contrat-engagement" element={<AuthGuard><SignDocumentsPages.ContractForm/></AuthGuard>}/>
              <Route path="/sign-required-documents/cybersecurity" element={<AuthGuard><SignDocumentsPages.CybersecurityForm/></AuthGuard>}/>
              {/* The root route must have AuthGuard. Nested routes are protected if the root has it.
                !!! Do not use AuthGuard for nested routes to prevent duplicated Confirmation Activity Prompt. !!!
              */}
             <Route path="" element={<AuthGuard><RequiredStepsGuard><DashboardPage/></RequiredStepsGuard></AuthGuard>}>
                <Route index element={<HomePage/>}/>
                <Route path="/ajouter-tutorat" element={<ScopeGuard role="tutor"><AddTutoratPage/></ScopeGuard>}/>
                <Route path="/ajouter-recuperation" element={<ScopeGuard role="teacher"><AddRecuperationPage/></ScopeGuard>}/>
                <Route path="/ajouter-maison-jeunes" element={<ScopeGuard role="partner"><AddMaisonJeunesPage/></ScopeGuard>}/>
                <Route path="/ajouter-tutorat-prive" element={<ScopeGuard role="partner"><AddTutoratPrivePage/></ScopeGuard>}/>
                <Route path="/ajouter-coach-meeting" element={<ScopeGuard role="coach"><AddCoachMeetingPage/></ScopeGuard>}/>
                <Route path="/aides-aux-devoirs" element={<ScopeGuard><AADPage/></ScopeGuard>}/>
                <Route path="/documents" element={<ScopeGuard><DocumentsPage/></ScopeGuard>}/>
                <Route path="/ressources" element={<ScopeGuard><ResourcesPage/></ScopeGuard>}/>
                <Route path="/faq" element={<ScopeGuard role="tutor"><FAQPage/></ScopeGuard>}/>
                <Route path="/nous-contacter" element={<ScopeGuard><ContactPage/></ScopeGuard>}/>
                <Route path="/play-time" element={<ScopeGuard><ConversationsListPage/></ScopeGuard>}>
                  <Route index element={<NoConversationSelected/>}/>
                  <Route path=":id" element={<PlayTimeViewPage/>}/>
                </Route>
                <Route path="/settings" element={<Settings.Root/>}>
                  <Route path="security" element={<Settings.Security/>}/>
                  <Route path="info" element={<ScopeGuard role="tutor"><Settings.Info/></ScopeGuard>}/>
                  <Route path="availabilities" element={<ScopeGuard role="tutor"><Settings.Preferences/></ScopeGuard>}/>
                  <Route path="schools" element={<ScopeGuard role="tutor"><Settings.Schools/></ScopeGuard>}/>
                  <Route path="subjects" element={<ScopeGuard role="tutor"><Settings.Subjects/></ScopeGuard>}/>
                </Route>
              </Route>

            <Route path="/inscription-tuteur/:period?" element={<TutorInscriptionPage/>}>
                <Route index element={<nestedTutorInscriptionPages.InfoPage/>}/>
                <Route path="general-info" element={<nestedTutorInscriptionPages.GeneralFormPage/>}/>
                <Route path="preferences" element={<nestedTutorInscriptionPages.PreferenceFormPage/>}/>
                <Route path="review" element={<nestedTutorInscriptionPages.ReviewFormPage/>}/>
            </Route>
            <Route path="*" element={<Error404/>}/>
          </Routes>
  )
}