
import React, { useMemo } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// COmponents
import { Table } from 'components/core/table';

// Utils
import { locale, parseDateTimeIgnoreTime } from 'utils/locale';
import classnames from 'classnames';

export function nameToInitials(firstname, lastname) {
    let value = [];
    if (lastname) {
        let parts = lastname.split(" ");
        for (let part of parts) {
            let sb = [];
            for (let subpart of part.split("-")) {
                if (subpart.length > 0) {
                    sb.push(subpart[0] + ".");
                }
            }
            value.push(sb.join("-"));
        }
    }
    return firstname+" "+value.join(" ");
  }

export const cellStyles = {
  "green": "bg-green-500 text-white",
  "red": "bg-red-500 text-white",
  "yellow": "bg-yellow-500 text-white",
  "blue": "bg-blue-500 text-white",
}

function DescriptionCell({game}){
    // if (!description) return null;
    const description = (game && game.final_rule);
    return <div className={classnames(!description && "text-gray-400")}>
        {description || "Aucune description"}
    </div>
}
export function TotalCell({game}){
  if (!game) return null;
  if (game.comment){
    return <span className={classnames("py-1 px-2 mx-auto rounded-sm text-center whitespace-nowrap  ring-2 ring-black", 
                    cellStyles[game.color])}>{ game.fraction}
                    {game.comment.startsWith("⚠️") && <span className='h-4 w-4 absolute top-0 -translate-y-1/2 rounded-full text-xs'>⚠️</span>}
                </span>
  }
  return <span className={classnames("py-1 px-2 mx-auto rounded-sm text-center whitespace-nowrap ring-2 ring-black", 
  cellStyles[game.color])}>{game.fraction}</span>
}

export function Cell({game, week, fraction, }){
  if (!week) return null;
  if (!game) return null;
  const isSelected = game.selected_weeks_idx.includes(week.idx);

  const content = (<span className={classnames("py-1 px-2 mx-auto rounded-sm text-center  whitespace-nowrap", 
  !isSelected?"opacity-25": "",
    cellStyles[week.color])}>{fraction || week.fraction}</span>)
  if (!isSelected) {
    return content;
  }
  return <span className=''>
          {content}
         </span>
}

function StudentComponent({name, firstname, lastname}){
  const initials = useMemo(()=>nameToInitials(firstname, lastname), [name]);
  return <div className="py-1">
      {initials}
  </div>
}

function PlayTimeGame({gameIdx, playTime, numGames}){
  const {t} = useTranslation();
  const headers = useMemo(()=>{
    const value = [
      {
        title: t("student"), 
        field: (d=>d? ({...d.student}): "student"),
        itemClassName: "!py-1  min-w-[190px]",
        className: "!pl-2",
        format: d=>(d.name),
        FormatComponent: StudentComponent
      },
    ];


    // Skip the weeks if no players
    if (playTime.results.length===0){
      return value;
    }
    // Append the weeks
    playTime.results[0].weeks.forEach((e, weekIdx)=>{
      value.push({
        title: (locale.format("%d %b")(parseDateTimeIgnoreTime(e.date))),
        field: (d=>d? {game: d.games[gameIdx], week: d.weeks[weekIdx]} :locale.format("%d %b")(parseDateTimeIgnoreTime(e.date))),
        headerId: locale.format("%d-%b")(parseDateTimeIgnoreTime(e.date)),
        itemClassName: "text-center",
        className: "text-center",
        FormatComponent: Cell,
      })
    });

    // Add the total cell
    value.push({
      title: t("total"),
      field: (d=>d?{game:d.games[gameIdx]}: "total"),
      itemClassName: "text-center",
      className: "text-center",
      FormatComponent: TotalCell
    })

    // Add the play time cell
    value.push({
        title: t("play-time.label"),
        field: (d=>d?{game:d.games[gameIdx]}: "play-time"),
        itemClassName: "text-left",
        className: "text-left",
        FormatComponent: DescriptionCell
      })
  
    return value;
  }, []);

  return <div>
         <div className='p-3 bg-gray-200 border-y text-lg font-medium top-0 sticky '>
          {numGames>1?t("game-idx", {idx: gameIdx+1}): t("game")}
          </div>
          <Table 
          // tableClassName="relative"
          headerRowClassName={"sticky top-[54px] bg-gray-100 border-b"}
          rowClassName={"hover:bg-gray-100"}
          data={playTime.results} 
          indexingKey={d=>d.student.ni}
          headers={headers}/>
        </div>
}

export function PlayTimeView({playTime}){
  // const {t} = useTranslation();
  const numGames = useMemo(()=>playTime? playTime.num_games: 0, [playTime]);
  return <div className=' w-full relative max-h-full overflow-auto'>
       
          <div className='space-y-8 mb-36'>
        {[...Array(numGames)].map((d, i)=>
        <PlayTimeGame key={i} gameIdx={i} playTime={playTime} numGames={numGames}/>)}
        </div>
        </div>
}