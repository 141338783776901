import React, { createContext, useMemo } from "react";

// Hooks
import { useAPI } from "hooks/useAPI";

// API
import { getConversation } from "api/conversations";
import { useParams } from "react-router-dom";

const ConversationContext = createContext({});

const ConversationContextProvider = ({ children }) => {
  const {id} = useParams();
  const params = useMemo(() => ({id, markAsSeen: true}), [id]);
  const [conversation, props] = useAPI(getConversation, params, {validateParams: (p) => !!p.id});

  return (
    <ConversationContext.Provider value={{ conversation, ...props }}>
      {children}
    </ConversationContext.Provider>
  );
};

export { ConversationContext, ConversationContextProvider };