import React, { useContext } from "react"
import { ConversationContext, ConversationContextProvider } from "contexts/conversation"
import { Skeleton } from "components/core/skeleton";
import { PlayTimeView } from "components/play-time";
import { MessageList } from "components/conversation/messages";
import { locale, parseWithTimeZone } from "utils/locale";
import { EmptyState } from "components/core/empty";
import { useTranslation } from "react-i18next";


function UncontrolledPlayTimeView(){
    const {t} = useTranslation();
    const {conversation, loading} = useContext(ConversationContext);
    const playTime = conversation && conversation.play_time;
    if (loading || !conversation){
        return <div className="p-4 w-full">
            <Skeleton className='h-16 mb-4 w-full'/>
            <Skeleton className='h-64 w-full'/>
        </div>
    }
    return <div className="w-full">
         <div className='p-3'>
          <h1 className='text-3xl font-bold'>{ conversation.event && conversation.event.event_type && conversation.event.event_type.name} du {locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(conversation.event && conversation.event.date))}</h1>
          {playTime && <p className='text-lg'>Transmis le {locale.format("%d %B %Y à %H:%M")(parseWithTimeZone(playTime.event && playTime.event.transmitted ))}</p>}
          </div>
        <MessageList messages={conversation.messages} loading={loading}/>
        {!playTime?
        <EmptyState title={t("play-time.unavailable.title")} description={t("play-time.unavailable.description")}/>:
        <PlayTimeView playTime={conversation.play_time}/>}
        </div>
}

export function PlayTimeViewPage(){
    return <ConversationContextProvider>
                <UncontrolledPlayTimeView/>
    </ConversationContextProvider>
}
