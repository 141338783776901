import React, { useContext, useState} from 'react'
import { EmptyState } from 'components/core/empty';
import { Skeleton } from 'components/core/skeleton';
import { ConversationsContext, ConversationsContextProvider } from 'contexts/conversations'
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom'
import { locale, parseWithTimeZone } from 'utils/locale';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { Button } from 'components/core/button';
import { FiList } from 'react-icons/fi';

function ConversationList(){
    const {t} = useTranslation();
    const {conversations, markAsRead, loading} = useContext(ConversationsContext);

    return <div className=' hidden md:block md:w-1/4 md:min-w-[300px] max-w-[350px] border-r h-full'>
        {loading && <Skeleton className='h-8'/>}
        {conversations  && conversations.length===0 && <EmptyState
            title={t("play-time.empty.title")}
            description={t("play-time.empty.description")}
            />}
        {(conversations||[]).map((c) => 
        <NavLink to={`/play-time/${c.id}`} key={c.id} 
        onClick={()=>markAsRead(c.id)}
        className={({ isActive}) =>
            [
              "w-full transition-all border-b  p-2 flex items-center justify-between",
              c.has_unseen_messages && "font-semibold bg-orange-50",
              isActive ? "bg-gray-100 hover:bg-gray-200 " : "hover:bg-gray-100",
            ].join(" ")
          }>
            <div className='w-full'>
            <div className='flex items-center justify-between'>
                <p className=''>{c.event && c.event.event_type && c.event.event_type.name}</p>
            {c.has_unseen_messages&& <div className='rounded-full h-3 w-3 bg-orange-500'/>}
            </div>
            <div className='flex items-center justify-between'>
                <p>{c.event && c.event.team && c.event.team.name}</p>
                <p>{locale.format("%d %B %Y")(parseWithTimeZone(c.event && c.event.date))}</p>
            </div>
            </div>
        </NavLink>)}
    </div>
}

function ConversationListMobile(){
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {conversations, markAsRead, loading} = useContext(ConversationsContext);
    return <>
    <div className='block md:hidden p-3'>
        <Button color={"default"} size="md" onClick={() => setIsOpen(true)}><FiList className='mr-2'/> {t("play-time.open-dialog")}</Button>
    </div>
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg  bg-white w-full rounded border shadow max-h-[calc(100vh-100px)] overflow-auto">
            <div className='bg-gray-100 px-2 py-1 border-b font-medium'>
                <p>{t("play-time.select")}</p>
            </div>
      {loading && <Skeleton className='h-8'/>}
        {conversations  && conversations.length===0 && <EmptyState
            title={t("play-time.empty.title")}
            description={t("play-time.empty.description")}
            />}
        {(conversations||[]).map((c) => 
        <NavLink to={`/play-time/${c.id}`} key={c.id} 
        onClick={()=>markAsRead(c.id)}
        className={({ isActive}) =>
            [
              "w-full transition-all border-b  p-2 flex items-center justify-between",
              c.has_unseen_messages && "font-semibold bg-orange-50",
              isActive ? "bg-gray-100 hover:bg-gray-200 " : "hover:bg-gray-100",
            ].join(" ")
          }>
            <div>
            <div className='flex items-center justify-between'>
                <p className=''>{c.event && c.event.event_type && c.event.event_type.name}</p>
                <p>{c.event && c.event.team && c.event.team.name}</p>
            </div>
            <p>{locale.format("%d %B %Y")(parseWithTimeZone(c.event && c.event.date))}</p>
            </div>
            {c.has_unseen_messages&& <div className='rounded-full h-3 w-3 bg-orange-500'/>}
        </NavLink>)}
        </DialogPanel>
        </div>
    </Dialog>
  </>
}
function ConversationsListPage() {
    return <ConversationsContextProvider fields={["id", "user", "portal_user", "event"]}>
        <div className='h-[calc(100vh-50px)] w-full md:flex  '>
        <ConversationList/>
        <ConversationListMobile/>
        <Outlet/>
        </div>
    </ConversationsContextProvider>
}
export {ConversationsListPage}