import { fetchCall } from 'api';

/*
  Search conversations
*/
export function searchConversations({fields, page, limit, orderBy, orderDirection}){
  const opt = {
    method: "GET",
    endpoint: "/portal/conversations",
    query: {
      fields,
      page,
      limit,
      orderBy,
      orderDirection
    }
  };
  return fetchCall(opt);
}
/*
Retrieve a conversation
*/
export function getConversation({id, markAsSeen}){
  const opt = {
    method: "GET",
    endpoint: `/portal/conversations/${id}`,
    query: {
      markAsSeen
    }
  };
  return fetchCall(opt);
}