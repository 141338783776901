import React, { createContext, useCallback, useMemo } from "react";

// Hooks
import { useSearch } from "hooks/useSearch";

// API
import { searchConversations } from "api/conversations";

const ConversationsContext = createContext({});

const ConversationsContextProvider = ({ fields, children }) => {
  const params = useMemo(() => ({orderBy: 'created_at', orderDirection: 'desc' }), []);
  const [unsortedConversations, props] = useSearch(searchConversations, params, {fields});

  const markAsRead = useCallback((id) => {
    props.setResult((prev) => {
      return prev.map((c) => {
          if(c.id == id){
            return {...c, has_unseen_messages: null};
          }
          return c;
        })
    });
  }, []);

  const conversations = useMemo(() => {
    // Sort by event.date
    if (!unsortedConversations) return null;
    return unsortedConversations.sort((a, b) => {
      return new Date(b.event.date) - new Date(a.event.date);
    });
  }
  , [unsortedConversations]);

  return (
    <ConversationsContext.Provider value={{ conversations, markAsRead, ...props }}>
      {children}
    </ConversationsContext.Provider>
  );
};

export { ConversationsContext, ConversationsContextProvider };