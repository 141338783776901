import { fetchCall } from 'api';

/*
  Search events
*/
export function searchEvents({page, limit, orderBy, orderDirection, fromDate, toDate}){
  const opt = {
    method: "GET",
    endpoint: "/portal/events",
    query: {
      page,
      limit,
      orderBy,
      orderDirection,
      fromDate: fromDate && fromDate.toISOString(),
      toDate: toDate && toDate.toISOString()
    }
  };
  return fetchCall(opt);
}